import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([
      'chip px-3 py-2 w-auto rounded',
      _ctx.type === 'active'
        ? 'bg-green-200 text-green-700'
        : 'bg-red-200 text-red-700',
    ]),
    innerHTML: _ctx.text
  }, null, 10, _hoisted_1))
}