
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<"active" | "inactive">,
      default: "active",
    },
    text: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
});
